












import { Component, Vue } from 'vue-property-decorator';
import { debounce } from '@/utilities';
import { AdaptiveMode } from '@/store/modules/ui.store';
import Header from '@/components/Layout/Header.vue';
import Footer from '@/components/Layout/Footer/Footer.vue';
import Loading from '@/components/Loading.vue';
import Notification from '@/components/Ui/Notification.vue';

@Component({
  metaInfo: { titleTemplate: '%s | Garentii' },
  components: {
    Header,
    Loading,
    Notification,
    Footer
  }
})
export default class App extends Vue {
  public resizeDebounceTimeout = 25;

  public created() {
    this.resizeHandler();
  }

  public mounted() {
    window.addEventListener(
      'resize',
      debounce(this.resizeHandler, this.resizeDebounceTimeout),
      { passive: true },
    );
  }

  public beforeDestroy() {
    window.removeEventListener(
      'resize',
      this.resizeHandler,
    );
  }

  public resizeHandler() {
    const viewPort = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    this.$store.commit('SET_VIEW_PORT', viewPort);

    if (viewPort < 650) {
      if (this.$store.state.ui.adaptiveMode !== AdaptiveMode.XS) {
        this.$store.commit('SET_ADAPTIVE_MODE', AdaptiveMode.XS);
      }
    } else if (viewPort < 1000) {
      if (this.$store.state.ui.adaptiveMode !== AdaptiveMode.SM) {
        this.$store.commit('SET_ADAPTIVE_MODE', AdaptiveMode.SM);
      }
    } else {
      if (this.$store.state.ui.adaptiveMode !== AdaptiveMode.MD) {
        this.$store.commit('SET_ADAPTIVE_MODE', AdaptiveMode.MD);
      }
    }
  }
}
