import {IIcons} from '@/interfaces';

import RadioIcon from '@/assets/icons/radio_button.svg';
import RadioCheckedIcon from '@/assets/icons/radio_button_checked.svg';
import RadioIconSmall from '@/assets/icons/radio_button_small.svg';
import RadioCheckedIconSmall from '@/assets/icons/radio_button_checked_small.svg';
import EuroIcon from '@/assets/icons/euro.svg';
import downloadIcon from '@/assets/icons/download-icon.svg';
import CloseIcon from '@/assets/icons/close-icon.svg';
import FullStar from '@/assets/icons/full-star.svg';
import EmptyStar from '@/assets/icons/empty-star.svg';
import CheckedIcon from '@/assets/icons/check.svg';
import EmailIcon from '@/assets/icons/mail.svg';
import fbIcon from '@/assets/icons/fb-icon.svg';
import linkedinIcon from '@/assets/icons/linkedin-icon.svg';
import instagramIcon from '@/assets/icons/insta-icon.svg';
import mediumIcon from '@/assets/icons/medium-icon.svg';
import youTubeIcon from '@/assets/icons/youtube-icon.svg';
import BackArrow from '@/assets/icons/arrow_backward.svg';

export const ICONS: IIcons = {
  email: {
    component: EmailIcon,
  },
  radio: {
    component: RadioIcon,
  },
  radioChecked: {
    component: RadioCheckedIcon,
  },
  radioSmall: {
    component: RadioIconSmall,
  },
  radioCheckedSmall: {
    component: RadioCheckedIconSmall,
  },
  checkbox: {
    component: RadioIcon,
  },
  checkboxChecked: {
    component: RadioCheckedIcon,
  },
  euro: {
    component: EuroIcon,
  },
  downloadIcon: {
    component: downloadIcon,
  },
  closeIcon: {
    component: CloseIcon,
  },
  fullStar: {
    component: FullStar
  },
  emptyStar: {
    component: EmptyStar
  },
  checkedIcon: {
    component: CheckedIcon
  },
  fbIcon: {
    component: fbIcon,
  },
  linkedinIcon: {
    component: linkedinIcon,
  },
  instagramIcon: {
    component: instagramIcon,
  },
  mediumIcon: {
    component: mediumIcon,
  },
  youtubeIcon: {
    component: youTubeIcon,
  },
  backArrow: {
    component: BackArrow,
  },
};
