import { NavigationGuard } from 'vue-router';
import {
  getStorageLocale,
  loadLanguageAsync,
} from '@/plugins/vue-i18n.ts';
import { LocaleService } from '@/utilities/locale';
import store from '@/store';

const excludePaths = ['404', '500', 'maintenance'];
export const localeGuard: NavigationGuard = async (to, from, next) => {
  if (to.name === '500') { return next(); }

  const href = '' + window.location.href;
  const splitHref: string[] = href.split('?')[0].split('/');
  let isNotOnboarding: boolean = false;

  const supportedLangs = !store.getters.getSupportedLangs
      ? await store.dispatch('localeSettings').then(() => store.getters.getSupportedLangs)
      : store.getters.getSupportedLangs;
  const predictLang = !store.getters.getPredictLang && !store.getters.getUserFields('isAuthenticated')
      ? await store.dispatch('predictLocale')
      : store.getters.getPredictLang;

  const countryCode = LocaleService.getCountryFromDomain();
  const paramLocale = LocaleService.getLocaleCode(to.params.lang, countryCode);
  const storageLocale = getStorageLocale();

  /**
   * LOCALE GETTER PRIORITY:
   * 1) Url parameter
   * 2) Language from localStorage
   * 3) predicted locale
   */

  const locale = paramLocale && supportedLangs.includes(paramLocale)
    ? paramLocale
    : supportedLangs.includes(storageLocale)
      ? storageLocale
      : predictLang;

  const params = LocaleService.getSeparatedByCode(locale);

  isNotOnboarding = splitHref.findIndex((path) => isNotOnboarding = excludePaths.includes(path)) !== -1;
  if (isNotOnboarding) {
    return loadLanguageAsync(locale).then(() => next());
  }
  return !to.params.lang
    ? to.path
      ? window.location.pathname = `/${params.lang}${window.location.pathname || '/'}`
      : next({ name: 'index', params })
    : loadLanguageAsync(locale).then(() => next());
};
