













import { Component, Vue } from 'vue-property-decorator';
import { getSiteUrl } from '@/utilities/getUrl';

@Component
export default class FooterNavigation extends Vue {
  public get listItem() {
    return [
      {
        title: this.$tc('footer.navigation.imprint'),
        link: getSiteUrl('imprint')
      },
      {
        title: this.$tc('footer.navigation.privacyPolicy'),
        link: getSiteUrl('data-privacy')
      },
      {
        title: this.$tc('footer.navigation.termsConditions'),
        link: getSiteUrl('terms-and-conditions')
      },
      {
        title: this.$tc('footer.navigation.firstInformation'),
        link: getSiteUrl('first-information')
      },
      {
        title: this.$tc('footer.navigation.licenses'),
        link: getSiteUrl('licenses')
      }
    ];
  }
}
