


























import {Component, Prop, Vue} from 'vue-property-decorator';
import InformationIcon from '@/assets/icons/policyAccess/information.svg';

@Component({
  components: {
    InformationIcon
  }
})
export default class SectionText extends Vue {
  @Prop({
    type: String,
    required: false
  })
  private title!: string;

  @Prop({
    type: [String, Number],
    required: false
  })
  private desc!: string | number;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  private hr!: boolean;

}
