import {ActionTree} from 'vuex';
import store, { RootState } from '@/store';
import {ISuggestionAddressChecked, ISuggestionCheck, ISuggestionsPlaces, uniformLoadingEnum} from '@/interfaces';
import UserService from '@/services/api/user.service';

export const getSuggestedSession = () => {
  return store.getters.getSuggestSession
    ? {suggestSession: store.getters.getSuggestSession}
    : {};
};

export interface ISearchRequest {
  place: string;
  limitCountries: boolean;
  suggestSession: string;
}

export interface ISearchState {
  suggestSession: string;
  suggestionChecked: null | ISuggestionAddressChecked;
}

const initialState = (): ISearchState => ({
  suggestSession: '',
  suggestionChecked: null,

});
const state = initialState();

const getters = {
  getSuggestSession: (state: ISearchState) => state.suggestSession,
  getSuggestionChecked: (state: ISearchState) => state.suggestionChecked,
};

const mutations = {
  ['SET_SEARCH_SESSION'](state: ISearchState, payload: string) {
    state.suggestSession = payload;
  },
  ['SET_SUGGESTION_CHECKED'](state: ISearchState, payload: ISuggestionAddressChecked) {
    state.suggestionChecked = payload;
  }
};

const actions: ActionTree<ISearchState, RootState> = {

  getSuggestions: ({commit, state}, payload: ISearchRequest) => {
    commit('SET_UNIFORM_LOADING', { [uniformLoadingEnum.suggestionsLoad]: true });
    return UserService.getPropertiesAddresses({
      ...payload,
      ...getSuggestedSession(),
      ...{limitStreet: true}
    })
      .then((response: ISuggestionsPlaces) => {
        if (!state.suggestSession.length) {
          commit('SET_SEARCH_SESSION', response.session);
        }
        return response;
      })
      .finally(() =>
        commit('SET_UNIFORM_LOADING', { [uniformLoadingEnum.suggestionsLoad]: false }));
  },

  getPartnerUnits: ({commit}, payload) => {
    commit('SET_UNIFORM_LOADING', { [uniformLoadingEnum.unitsLoad]: true });
    return UserService.partnerUnits(payload.token, payload.buildingId, {query: payload.query})
      .then((response) => response)
      .finally(() =>
        commit('SET_UNIFORM_LOADING', { [uniformLoadingEnum.unitsLoad]: false }));
  },

  getPartnerBuildings: ({commit}, payload) => {
    commit('SET_UNIFORM_LOADING', { [uniformLoadingEnum.buildingsLoad]: true });
    return UserService.partnerBuildings(payload.token, {query: payload.query})
      .then((response) => {
        return response;
      })
      .finally(() =>
        commit('SET_UNIFORM_LOADING', { [uniformLoadingEnum.buildingsLoad]: false }));
  },

  checkSuggestion: ({commit}, payload: ISuggestionCheck) => {
    commit('SET_UNIFORM_LOADING', { [uniformLoadingEnum.checkSuggestion]: true });
    return UserService.suggestionCheck(payload)
      .then((response: ISuggestionAddressChecked) => {
        if (response) {
          commit('SET_SUGGESTION_CHECKED', response);
        }
        return response;
      })
      .finally(() =>
        commit('SET_UNIFORM_LOADING', { [uniformLoadingEnum.checkSuggestion]: false }));
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
