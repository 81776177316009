import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { localeGuard } from '@/router/guards/localeGuard';
import { tokenGuard } from '@/router/guards/tokenGuard';
import { congratulationGuard } from '@/router/guards/congratulationGuard';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/:lang(de|en)',
    beforeEnter: tokenGuard,
    component: {
      render: (c) => c('router-view'),
    },
    children: [
      {
        path: '/',
        name: 'index',
        component: () => import(
          /* webpackChunkName: "HomePage" */
          '@/pages/Home/Home.page.vue'
          ),
      },
      {
        path: 'new-password',
        name: 'new-password',
        component: () => import(
          /* webpackChunkName: "NewPasswordPage" */
          '@/pages/NewPassword/NewPassword.page.vue'
          ),
      },
      {
        path: 'congratulation',
        name: 'congratulation',
        beforeEnter: congratulationGuard,
        component: () => import(
          /* webpackChunkName: "HomePage" */
          '@/pages/Congratulation/Congratulation.page.vue'
          ),
      },
      {
        path: 'access-policy',
        name: 'access-policy',
        component: () => import(
          /* webpackChunkName: "PolicyAccessPage" */
          '@/pages/PolicyDepositAccess/PolicyDepositAccess.page.vue'
          ),
      },
      {
        path: 'rate',
        component: {
          render(c) { return c('router-view'); },
        },
        children: [
          {
            path: ':id',
            name: 'rating',
            component: () => import(
              /* webpackChunkName: "RatingPage" */
              '@/pages/Rating/Rating.page.vue'
              ),
          },
          {
            path: 'thank-you',
            name: 'rating-thank',
            component: () => import(
              /* webpackChunkName: "RatingThankYouPage" */
              '@/pages/Rating/Rating.thankYou.page.vue'
              )
          },
        ]
      },
      {
        path: '*',
        redirect: () => {
          return {name: '404'};
        }
      },
    ]
  },
  {
    path: '/404',
    name: '404',
    component: () => import(
      /* webpackChunkName: "Error400" */
      '@/pages/Errors/Error.404.vue'
      ),
  },
  {
    path: '/500',
    name: '500',
    component: () => import(
      /* webpackChunkName: "Error500" */
      '@/pages/Errors/Error.500.vue'
      ),
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import(
      /* webpackChunkName: "ErrorMaintenance" */
      '@/pages/Errors/Error.maintenance.vue'
      ),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(localeGuard);

export default router;
