
































import {Component, Vue} from 'vue-property-decorator';

@Component({
  inheritAttrs: false,
})
export default class SelectSuggestions extends Vue {
  private timeOut: ReturnType<typeof setTimeout> | null = null;

  private onSearch(event) {
    if (!event) { return; }
    if (this.timeOut) { clearTimeout(this.timeOut); }

    this.timeOut = setTimeout(() => {
      if (event) { this.$emit('search', event); }
    }, 500);
  }
}
