import { VueConstructor } from 'vue';

export interface IDictionary<T> {
  [key: string]: T;
}

export type ValueOf<T> = T[keyof T];

export interface IAuthCredentials {
  login: string;
  password: string;
  rememberMe: boolean;
}

export interface IIcons {
  [key: string]: {
    component: VueConstructor,
    props?: {
      name: string,
    },
  };
}

export interface ISocialLinksList {
  icon: string;
  link: string;
  label: string;
}

export enum Currency {
  EUR = 'EUR',
  USD = 'USD',
  GBP = 'GBP',
}

export type CurrencyType = keyof typeof Currency;

export enum Language {
  EN = 'en',
  DE = 'de',
}
export enum Country {
  DE = 'de',
  EN = 'en',
  AT = 'at',
  NL = 'nl',
}

export type LanguageCodeType = ValueOf<Language>;

export enum LanguageNameEnum {
  de = 'Deutsch',
  en = 'English'
}

export type LanguageNameType = ValueOf<LanguageNameEnum>;

export interface ILanguage {
  name: LanguageNameType;
  code: LanguageCodeType;
}
export interface IDescription {
  title?: string|number;
  subtitle?: string|number;
  text?: string|number;
  component?: VueConstructor;
  mobileComponent?: VueConstructor;
  class?: string;
}

export enum StepEnum {
  TYPE = 'TYPE',
  DETAILS = 'DETAILS',
  PROPERTIES_TYPE = 'PROPERTIES_TYPE',
  PORTFOLIO = 'PORTFOLIO',
  LEASING_TOOL = 'LEASING_TOOL',
  SECURITY_DEPOSIT = 'SECURITY_DEPOSIT',
  FURNISHED = 'FURNISHED',
  AGREEMENT = 'AGREEMENT',
}

export type LastCompletedPageType = ValueOf<StepEnum>;

export enum LandlordEnum {
  PRIVATE = 'PRIVATE',
  PROPERTY_MANAGER = 'PROPERTY_MANAGER',
  INSTITUTIONAL = 'INSTITUTIONAL',
  MUNICIPALITY = 'MUNICIPALITY',
}

export type LandlordType = ValueOf<LandlordEnum>;

export enum FurnishedEnum {
  FULL = 'FULL',
  NOT = 'NOT',
  SEMI = 'SEMI'
}

export type FurnishedType = ValueOf<FurnishedEnum>;

export enum LeasingToolEnum {
  YARDI = 'YARDI',
  MRI = 'MRI',
  IMS = 'IMS',
  IX_HAUSE = 'IX_HAUSE',
  ARGO_WEB = 'ARGO_WEB',
  SAP = 'SAP',
  SEV_DESK = 'SEV_DESK',
  LEXWARE = 'LEXWARE',
  EXCEL = 'EXCEL',
  PAPER = 'PAPER',
  OTHER = 'OTHER'
}

export type LeasingToolType = ValueOf<LeasingToolEnum>;

export enum PortfolioSizeEnum {
  UNDER_100 = 'UNDER_100',
  UNDER_1000 = 'UNDER_1000',
  UNDER_10000 = 'UNDER_10000',
  UNDER_20000 = 'UNDER_20000',
  OVER_20000 = 'OVER_20000'
}

export type PortfolioSizeType = ValueOf<PortfolioSizeEnum>;

export enum PropertiesTypeEnum {
  RESIDENTIAL = 'RESIDENTIAL',
  COMMERCIAL = 'COMMERCIAL',
  BOTH = 'BOTH',
  MULTIFAMILY = 'MULTIFAMILY',
  SINGLE_FAMILY = 'SINGLE_FAMILY',
}

export type PropertiesType = ValueOf<PropertiesTypeEnum>;

export interface ISuggestionAddress {
  placeId: string | null;
  title: string | null;
  highlightedTitle?: string;
  country?: LanguageCodeType;
  city?: string;
  street?: string;
  streetNumber?: string;
  zip?: string;
  type?: PropertiesTypeEnum;
}

export interface ISuggestionCheck {
  suggestion: ISuggestionAddress;
  session: string;
  streetNumber?: string;
}

export interface ISuggestionAddressChecked {
  suggestion: ISuggestionAddress;
  streetNumber: string | null;
  zip: string | null;
}

export interface ISuggestionsPlaces {
  session: string;
  suggestions: ISuggestionAddress[];
}

export interface IDocument {
  id: string;
  fileName: string;
  fileType: string;
  url: string;
  size: string;
  createdAt: string;
}

export enum InsuranceLocaleEnum {
  DE = 'de'
}

export type InsuranceLocaleType = ValueOf<InsuranceLocaleEnum>;

export enum addressErrors {
  streetNumber = 'streetNumber',
  street = 'street',
  city = 'city',
  country = 'country',
  zip = 'zip'
}

export const insuranceLocale: InsuranceLocaleEnum = InsuranceLocaleEnum.DE;

export enum notOnboardingPath {
  rate = 'rate',
  'new-password' = 'new-password',
  // @ts-ignore
  '404' = '404', '500' = '500',
  maintenance = 'maintenance'
}

export enum withLangCodesPath {
  index = 'index',
  'access-policy' = 'access-policy',
}

export enum uniformLoadingEnum {
  suggestionsLoad = 'suggestionsLoad',
  checkSuggestion = 'checkSuggestion',
  propertyLoad = 'propertyLoad',
  unitsLoad = 'unitsLoad',
  buildingsLoad = 'buildingsLoad',
}

export interface IDictionaryObject {
  name: string;
  value: object;
}

export enum landlordTypeEnum {
  PARTNER = 'PARTNER'
}

export enum documentNamesEnum {
  TERMS_OF_USE = 'terms-of-use',
  SAMPLE = 'sample',
  PRIVACY_POLICY = 'privacy-policy',
}
