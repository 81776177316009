import { ActionTree } from 'vuex';
import { RootState } from '@/store';
import { IDictionary } from '@/interfaces';

export enum AdaptiveMode {
  XS = 'XS',
  SM = 'SM',
  MD = 'MD',
}

export interface IUiState {
  adaptiveMode: AdaptiveMode;
  loadingDictionary: IDictionary<boolean>;
  viewPort: number;
  currentStep: number;
  stepLength: number;
  uniformLoadingDictionary: ILoadingData;
}

export interface ILoadingData {
  [key: string]: boolean;
}

const initialState = (): IUiState => ({
  adaptiveMode: AdaptiveMode.MD,
  loadingDictionary: {},
  viewPort: 0,
  currentStep: 0,
  stepLength: 0,
  uniformLoadingDictionary: {},
});
const state = initialState();

const getters = {
  isLoading: (state: IUiState) => Object.keys(state.loadingDictionary)
    .map((key: string) => state.loadingDictionary[key])
    .some((isLoading: boolean) => isLoading),
  isMobileAdaptiveMode: (state: IUiState) => state.adaptiveMode === AdaptiveMode.XS,
  isTabletAdaptiveMode: (state: IUiState) => state.adaptiveMode === AdaptiveMode.SM,
  isDesktopAdaptiveMode: (state: IUiState) => state.adaptiveMode === AdaptiveMode.MD,
  currentStep: (state: IUiState) => state.currentStep,
  stepLength: (state: IUiState) => state.stepLength,
  isUniformLoading: (state: IUiState) => {
    return (key: string) => key in state.uniformLoadingDictionary && state.uniformLoadingDictionary[key];
  },
};

const mutations = {
  ['SET_ADAPTIVE_MODE'](state: IUiState, payload: AdaptiveMode) {
    state.adaptiveMode = payload;
  },
  ['SET_LOADING'](state: IUiState, payload: ILoadingData) {
    state.loadingDictionary = {...state.loadingDictionary, ...payload};
  },
  ['SET_VIEW_PORT'](state: IUiState, payload: number) {
    state.viewPort = payload;
  },
  ['SET_CURRENT_STEP'](state: IUiState, payload: number) {
    state.currentStep = payload;
  },
  ['SET_STEP_LENGTH'](state: IUiState, payload: number) {
    state.stepLength = payload;
  },
  ['SET_UNIFORM_LOADING'](state: IUiState, payload: ILoadingData) {
    state.uniformLoadingDictionary = {...state.uniformLoadingDictionary, ...payload};
  },
};

const actions: ActionTree<IUiState, RootState> = {
  setStep: ({commit}, step: number) => {
    commit('SET_CURRENT_STEP', step);
  },
  subtractStep: ({state, dispatch}) => {
    if (state.currentStep > 0) {
      dispatch('setStep', state.currentStep - 1);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
