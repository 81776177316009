




















import {Component, Vue} from 'vue-property-decorator';
import {getIconByLanguage} from '@/dictionaries/icons.language.dictionary';
import {ILanguage, LanguageNameEnum} from '@/interfaces';

@Component({
  inheritAttrs: false,
})
export default class LanguageDropdown extends Vue {
  private getIconByLanguage = getIconByLanguage;

  private get getAvailableLanguages(): ILanguage[] {
    return this.$store.getters.getCurrentSettings?.languages.map((language) =>
      ({name: LanguageNameEnum[language], code: language}));
  }

  public get attrs() {
    return {
      ...this.$attrs,
      value: this.$store.getters.language,
      items: this.getAvailableLanguages,
      'item-text': 'name',
      'item-value': 'code',
      'hide-details': true,
    };
  }

  public get listeners() {
    return {
      ...this.$listeners,
      input: (language) => this.$store.dispatch('setLanguage', language)
    };
  }
}
