






































import {Component, Vue} from 'vue-property-decorator';
import LogoIcon from '@/components/Layout/Logo.vue';
import LanguageDropdown from '@/components/LanguageDropdown.vue';

@Component({
  components: {
    LogoIcon,
    LanguageDropdown,
  }
})
export default class Header extends Vue {
  private get progressStyleObject(): object {
    const width = this.$store.getters.currentStep && this.$store.getters.stepLength
      ? `${this.$store.getters.currentStep * 100 / this.$store.getters.stepLength}%`
      : 0;
    return { width };
  }

  private get isHomePage() {
    return this.$route.name === 'index';
  }

  private subtractStep() {
    this.$store.dispatch('subtractStep');
  }

  private get getPricePlan() {
    return this.$store.getters.getSelectedPricePlan;
  }

  private createPayment() {
    this.$store.dispatch('createPayment', this.getPricePlan.paymentPeriod)
      .then(() => {
        this.$router.push({name: 'payment'});
      });
  }

  private get currentStep(): number {
    return this.$store.getters.currentStep;
  }

  private get headerHeight(): number {
    return this.$store.getters.isMobileAdaptiveMode ? 76 : 80;
  }

  private get getClasses() {
    return {
      'offers-header': this.$route.name === 'offers',
      'no-border': !this.isHomePage
    };
  }
}
