import { render, staticRenderFns } from "./Section.text.vue?vue&type=template&id=252e14c0&scoped=true&lang=pug&"
import script from "./Section.text.vue?vue&type=script&lang=ts&"
export * from "./Section.text.vue?vue&type=script&lang=ts&"
import style0 from "./Section.text.vue?vue&type=style&index=0&id=252e14c0&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "252e14c0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCol,VRow,VTooltip})
