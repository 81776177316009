








import { Component, Vue, Prop } from 'vue-property-decorator';

type UiColorType = 'default' | 'primary' | 'secondary' | 'tertiary' | 'danger'
  | 'border-primary';

@Component({
  inheritAttrs: false
})
export default class Button extends Vue {
  @Prop({
    type: String,
    required: false,
    default: 'default'
  })
  public color!: UiColorType;

  @Prop({
    type: Boolean,
    required: false,
  })
  public disabled!: false;

  public get attrs() {
    return {
      ...this.$attrs,
      ripple: false,
      class: this.classObject,
    };
  }

  public get classObject() {
    return {
      [`button-${this.color}`]: this.color,
      disabled: this.disabled,
    };
  }
}
