import Vue, { VNode } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import VueI18n from 'vue-i18n';

import Vuetify from 'vuetify/lib/framework';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import VueMeta from 'vue-meta';
// @ts-ignore
import themeStyles from '@/styles/stylus/variables/colors.styl';
import {ICONS} from '@/dictionaries/icons.dictionary';

import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import Vuebar from 'vuebar';
import VueCurrencyFilter from 'vue-currency-filter';
import VueMask from 'v-mask';
import InlineSvg from 'vue-inline-svg';

import Button from '@/components/Ui/Button.vue';
import Select from '@/components/Ui/Select.vue';
import SelectAddress from '@/components/Ui/SelectSuggestions.vue';
import Input from '@/components/Ui/Input.vue';
import SectionText from '@/components/Section/Section.text.vue';
import TextArea from '@/components/Ui/TextArea.vue';
import Checkbox from '@/components/Ui/Checkbox.vue';
import Radio from '@/components/Ui/Radio.vue';
import Modal from '@/components/Ui/Modal.vue';
import InputPhone from '@/components/Ui/InputPhone.vue';

import Notification from 'vue-notification';
import {currencyOptions} from '@/dictionaries/symbols.currency.dictionary';
import {EnvProvider} from '@/utilities';
import { i18Options } from '@/plugins/vue-i18n';
import getStatic from '@/plugins/static.plugin';

Vue.config.productionTip = false;

Vue.use(getStatic);
Vue.use(VueI18n);
export const i18n = new VueI18n(i18Options);
Vue.use(Vuetify);
const vuetify = new Vuetify({
  theme: {
    dark: false,
    default: 'light',
    options: {
      customProperties: false,
      variations: false
    },
    themes: {
      light: {
        primary: themeStyles.primaryColor,
        secondary: themeStyles.secondaryColor,
        tertiary: themeStyles.tertiaryColor,
        success: themeStyles.successColor,
        error: themeStyles.errorColor,
        warning: themeStyles.warningColor,
        info: themeStyles.infoColor,
      },
    }
  },
  icons: {
    values: ICONS
  },
});

Vue.use(VueMeta, {
  keyName: 'metaInfo',
  refreshOnceOnNavigation: true,
});
Vue.use(VueMoment, {moment});
Vue.use(Vuebar);
Vue.use(Notification);
Vue.use(VueCurrencyFilter, currencyOptions);
Vue.use(VueMask);

Vue.component('Button', Button);
Vue.component('Select', Select);
Vue.component('SelectAddress', SelectAddress);
Vue.component('Input', Input);
Vue.component('TextArea', TextArea);
Vue.component('Checkbox', Checkbox);
Vue.component('Radio', Radio);
Vue.component('Modal', Modal);
Vue.component('InputPhone', InputPhone);
Vue.component('SectionText', SectionText);
Vue.component('SvgInline', InlineSvg);

const render = (createElement: typeof Vue.prototype.$createElement): VNode => createElement(App);

Sentry.init({
  Vue,
  dsn: EnvProvider('SENTRY_DSN'),
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [EnvProvider('URL'), /^\//],
    }),
  ],
  tracesSampleRate: parseFloat(EnvProvider('SENTRY_TRACES_SAMPLE_RATE')),
  environment: EnvProvider('SENTRY_ENVIRONMENT'),
  release: EnvProvider('SENTRY_RELEASE'),
  logErrors: true
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render
}).$mount('#app');
