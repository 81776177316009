import {Currency, CurrencyType} from '@/interfaces';

export const symbolCurrencyDictionary: Record<CurrencyType, string> = {
  [Currency.EUR]: '&#8364;',
  [Currency.USD]: '&#36;',
  [Currency.GBP]: '&#163;',
};

export const getSymbolByCurrency = (currency: CurrencyType) => symbolCurrencyDictionary[currency];

export const currencyOptions = [
  {
    name: 'EUR',
    symbol: '€',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'back',
    symbolSpacing: true,
    avoidEmptyDecimals: '00',
  },
  {
    name: 'USD',
    symbol: '$',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'back',
    symbolSpacing: true,
    avoidEmptyDecimals: '00',
  },
];
