// @ts-ignore
import { CookieService } from './cookie.service';

export interface ITokenService {
  getToken: () => string | null;
  setToken: (token: string | null) => void;
  resetToken: () => void;
}

const tokenKey = 'token';

const getToken = (): string | null => CookieService.get(tokenKey);
const setToken = (token: string | null): void => CookieService.set(tokenKey, token);
const resetToken = (): void => CookieService.remove(tokenKey, {path: '/'});

const TokenService: ITokenService = {
  getToken,
  setToken,
  resetToken,
};
export default TokenService;
