













import {Vue, Component, Prop} from 'vue-property-decorator';
import CloseIcon from '@/assets/icons/close.svg';

@Component({
  components: {
    CloseIcon
  }
})
export default class Modal extends Vue {
  @Prop({
    required: false,
    type: Boolean,
    default: false
  })
  private visible!: boolean;

  @Prop({
    required: false,
    type: String,
    default: '580'
  })
  private modalWidth!: string;
}
