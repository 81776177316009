import {ActionTree} from 'vuex';
import { RootState } from '@/store';
import UserService, {
  IUser,
  IPasswordChangeInfo,
  IPhone,
  IPolicyAccess,
  IPolicyLandlordDetails,
  IAddress,
  IAddressManual,
  IAddressRequest,
  IConfirmLandlordDetailsInfo,
  IPolicyExistingLandlordRequest, IResponsePageableArray, IBeneficiary, IPartnerAcceptProperty, IPropertyAddressManual,
} from '@/services/api/user.service';
import {getField, updateField} from 'vuex-map-fields';
import TokenService from '@/services/data/token.service';
import {
  ISuggestionAddress,
  ISuggestionAddressChecked,
  LandlordEnum,
  Language,
  LeasingToolEnum, withLangCodesPath
} from '@/interfaces';
import router from '@/router';
import {getQueryObject} from '@/utilities/searchToObject';
import {cloneObject} from '@/utilities';
import { getCountry, getLang, loadLanguageAsync } from '@/plugins/vue-i18n';
import { LocaleService } from '@/utilities/locale';

const defaultPhone = (): IPhone => ({
  dialCode: null,
  number: null
});

export const defaultSuggestionAddress = (): ISuggestionAddress => ({
  placeId: null,
  title: null,
});

export const defaultAddressManual = (): IAddressManual => ({
  street: null,
  streetNumber: null,
  zip: null,
  city: null,
  country: null,
});

export const defaultPropertyAddressManual = (): IPropertyAddressManual => ({
  street: null,
  streetNumber: null,
  zip: null,
  city: null
});

export const defaultAddress = (): IAddress => ({
  address: defaultAddressManual(),
  fullAddress: '',
  suggestionAddress: null,
});

export const defaultAddressRequest = (): IAddressRequest => ({
  address: defaultAddressManual(),
  suggestionAddress: defaultSuggestionAddress(),
  suggestSession: ''
});

export const defaultSuggestionChecked = (): ISuggestionAddressChecked => ({
  suggestion: defaultSuggestionAddress(),
  streetNumber:	null,
  zip: null
});

export const defaultUser = (): IUser => ({
  lastCompletedPage: null,
  token: '',
  type: null,
  firstName: '',
  lastName: '',
  email: '',
  phone: defaultPhone(),
  companyName: '',
  address: defaultAddress(),
  furnished: null,
  leasingTool: null,
  manualLeasingTool: '',
  portfolioSize: null,
  propertiesType: null,
  offerAlternativeSecurityDeposit: null,
  newsAgreement: false
});

const defaultLandlordDetails = (): IPolicyLandlordDetails => ({
  type: '',
  firstName: '',
  lastName: '',
  companyName: '',
  email: '',
  phone: defaultPhone(),
  address: defaultAddress(),
  propertyType: '',
  unitName: '',
  beneficiary: defaultBeneficiary(),
  isLandlordBeneficiary: null,
});

export const defaultBeneficiary = (): IBeneficiary => ({
  id: null,
  name: '',
  address: defaultAddress(),
});

// Lifetime of thank you page if button won`t be clicked
const lifetime: number = 30 * 60 * 1000;

export interface IUserState {
  user: IUser;
  token: string;

  selectedPricePlan: IPricePlan;
  paymentKey: string;
  policyAccessInfo: IPolicyAccess | {};
  policyLandlordDetails: IPolicyLandlordDetails;
  beneficiaries: IBeneficiary[];
  beneficiariesCount: number;
  beneficiariesPage: number;
}

interface IPricePlan {
  paymentPeriod: string;
  price: number;
}

const initialState = (): IUserState => ({
  user: defaultUser(),
  token: '',

  selectedPricePlan: {
    price: 0,
    paymentPeriod: ''
  },
  paymentKey: '',
  policyAccessInfo: {},
  policyLandlordDetails: defaultLandlordDetails(),
  beneficiaries: [],
  beneficiariesCount: 0,
  beneficiariesPage: 1
});
const state = initialState();

const getters = {
  getUserFields: (state: IUserState) => getField(state),
  token: (state: IUserState) => state.token,
  user: (state: IUserState) => state.user,
  getSelectedPricePlan: (state: IUserState) => state.selectedPricePlan,
  getAcceptPolicy: (state: IUserState): IPolicyAccess | {} => state.policyAccessInfo,
  getPolicyLandlordDetails: (state: IUserState): IPolicyAccess | {} => state.policyLandlordDetails,
  getBeneficiaries: (state: IUserState): IBeneficiary[] | [] => state.beneficiaries,
  getBeneficiariesCount: (state: IUserState): number | null => state.beneficiariesCount,
  getBeneficiariesPage: (state: IUserState): number | null => state.beneficiariesPage,
  language: () => getLang(),
  country: () => getCountry()
};

const mutations = {
  updateUserField(state: IUserState, field: string) {
    return updateField(state, field);
  },
  ['SET_USER'](state: IUserState, payload: IUser) {
    state.user = payload;
  },
  ['SET_TOKEN'](state: IUserState, payload: string) {
    state.token = payload;
  },
  ['SET_ACCESS_INFO'](state: IUserState, payload ) {
    state.policyAccessInfo = payload;
  },
  ['SET_LANDLORD_DETAILS'](state: IUserState, payload ) {
    state.policyLandlordDetails.email = payload.landlordEmail;
    state.policyLandlordDetails.phone = payload.phoneNumber
      ? payload.phoneNumber : { dialCode: '', number: null };
  },
  ['SET_BENEFICIARIES'](state: IUserState, payload: IBeneficiary[] ) {
    state.beneficiaries = [
      ...cloneObject(state.beneficiaries),
      ...payload,
    ];
  },
  ['SET_BENEFICIARIES_COUNT'](state: IUserState, payload: number) {
    state.beneficiariesCount = payload;
  },
  ['SET_BENEFICIARIES_PAGE'](state: IUserState, payload: number) {
    state.beneficiariesPage = payload;
  },
};

const actions: ActionTree<IUserState, RootState> = {
  setLanguage: ({getters}, language: string) => {
    if (router.currentRoute.name && router.currentRoute.name in withLangCodesPath) {
      router.push({
        params: {lang: (language as Language)},
        query: getQueryObject()
      });
    } else {
      loadLanguageAsync(LocaleService.getLocaleCode(language, getters.country)!);
    }
  },
  setToken: ({commit, dispatch}, token: string | null) => {
    if (token) {
      commit('SET_TOKEN', token);
      TokenService.setToken(token);
    } else {
      dispatch('resetToken');
    }
  },
  resetToken: ({commit}) => {
    commit('SET_TOKEN', null);
    TokenService.resetToken();
    router.push({name: 'index'});
  },
  saveUserType: ({getters, dispatch}) => {
    return UserService.saveUserType({
      type: getters.user.type,
      ...(getters.token ? { token: getters.token } : [])
    })
      .then((response) => dispatch('setToken', response!.token));
  },
  saveUserDetails: ({getters: {token, getSuggestSession, user}}, manual: boolean) => {
    const {
      firstName,
      lastName,
      email,
      companyName,
    } = user;

    return UserService.saveUserDetails({
      firstName,
      lastName,
      email,
      phone: user.phone.number ? user.phone : null,
      companyName,
      address: {
        address: manual ? user.address.address : null,
        suggestSession: manual ? null : getSuggestSession,
        suggestionAddress: manual ? null : user.address.suggestionAddress,
      },
      token,
    });
  },
  saveUserPropertyType: ({getters}) => {
    return UserService.saveUserPropertyType({
      token: getters.token,
      propertiesType: getters.user.propertiesType
    });
  },
  saveUserPortfolio: ({getters}) => {
    return UserService.saveUserPortfolio({
      token: getters.token,
      portfolioSize: getters.user.portfolioSize
    });
  },
  saveUserLeasingTools: ({getters}, manual: boolean) => {
    return UserService.saveUserLeasingTools({
      token: getters.token,
      leasingTool: manual ? LeasingToolEnum.OTHER : getters.user.leasingTool,
      manualLeasingTool: manual ? getters.user.manualLeasingTool : null,
    });
  },
  saveUserSecurityDeposit: ({getters}) => {
    return UserService.saveUserSecurityDeposit({
      token: getters.token,
      offerAlternativeSecurityDeposit: getters.user.offerAlternativeSecurityDeposit
    });
  },
  saveUserFurnished: ({getters}) => {
    return UserService.saveUserFurnished({
      token: getters.token,
      furnished: getters.user.furnished
    });
  },
  saveUserAgreement: ({getters}) => {
    return UserService.saveUserAgreement({
      token: getters.token,
      newsAgreement: getters.user.newsAgreement,
      language: getters.language
    })
      .then(() => localStorage.setItem('lifetime', String(new Date().getTime() + lifetime)))
      .catch(() => localStorage.removeItem('lifetime'));
  },

  getAcceptPolicy: ({commit}, token: string) => {
    commit('SET_LOADING', {policyAccessInfo: true});
    return UserService.getAcceptPolicy(token)
      .then((response) => {
        commit('SET_ACCESS_INFO', response);
        if (!response.landlordExisting) {
          commit('SET_LANDLORD_DETAILS', response);
        }
      })
      .finally(() => commit('SET_LOADING', {policyAccessInfo: false}));
  },
  confirmLandlordAcceptance: ({commit, getters}, payload: IConfirmLandlordDetailsInfo ) => {
    commit('SET_LOADING', {policyAccessInfo: true});
    const {
      propertyType,
      unitName,
      beneficiary
    } = getters.getPolicyLandlordDetails;
    const details: IPolicyExistingLandlordRequest = {
      propertyType,
      unitName,
      beneficiary: {
        id: beneficiary.id,
        name: beneficiary.name,
        tag: beneficiary.tag ? beneficiary.tag : null,
        address: beneficiary.id ? null : {
          address: payload.isManualAddress ? beneficiary.address.address : null,
          suggestSession: payload.isManualAddress ? null : getters.getSuggestSession,
          suggestionAddress: payload.isManualAddress ? null : beneficiary.address.suggestionAddress
        },
      }
    };
    return UserService.confirmLandlordAcceptance(payload.token, details)
      .finally(() => commit('SET_LOADING', {policyAccessInfo: false}));
  },

  confirmLandlordDetails: ({getters, commit},
                           payload: {isLandlordManualAddress: boolean,
                                    isBeneficiaryManualAddress: boolean,
                                    token: string}) => {
    commit('SET_LOADING', {confirmLandlordDetails: true});
    const { beneficiary } = getters.getPolicyLandlordDetails;

    const details = {
      ...getters.getPolicyLandlordDetails,
      companyName: getters.getPolicyLandlordDetails.type === LandlordEnum.PRIVATE ? null :
        getters.getPolicyLandlordDetails.companyName,
      address: {
        address: payload.isLandlordManualAddress ?
          getters.getPolicyLandlordDetails.address.address : null,
        suggestSession: payload.isLandlordManualAddress ?
          null : getters.getSuggestSession,
        suggestionAddress: payload.isLandlordManualAddress ?
          null : getters.getPolicyLandlordDetails.address.suggestionAddress,
      },
      phone: getters.getPolicyLandlordDetails.phone.number ? getters.getPolicyLandlordDetails.phone : null,
      beneficiary: getters.getPolicyLandlordDetails.isLandlordBeneficiary ? null :
        {
          name: beneficiary.name,
          tag: beneficiary.tag ? beneficiary.tag : null,
          address: {
            address: payload.isBeneficiaryManualAddress ?
              beneficiary.address.address : null,
            suggestSession: payload.isBeneficiaryManualAddress ?
              null : getters.getSuggestSession,
            suggestionAddress: payload.isBeneficiaryManualAddress ?
              null : beneficiary.address.suggestionAddress,
          },
        }
    };
    return UserService.confirmLandlordDetails(payload.token, details)
      .finally(() => commit('SET_LOADING', {confirmLandlordDetails: false}));
  },

  confirmAccount: ({commit}, payload: IPasswordChangeInfo) => {
    commit('SET_LOADING', {password: true});
    return UserService.confirmAccount(payload)
      .finally(() => commit('SET_LOADING', {password: false}));
  },
  setRate: ({commit}, payload) => {
    commit('SET_LOADING', {rating: true});
    return UserService.setRate(payload)
      .then(() => Promise.resolve())
      .finally(() => commit('SET_LOADING', {rating: false}));
  },
  rateCheck: ({commit}, payload: string) => {
    commit('SET_LOADING', {rating: true});
    return UserService.rateCheck(payload)
      .then((response: boolean) => response)
      .finally(() => commit('SET_LOADING', {rating: false}));
  },

  getUserByToken: ({getters, commit, dispatch}) => {
    return UserService.getUserByToken(getters.getUserFields('token'))
      .then((response: IUser|null) => {
        if (response) {
          const userModel = {
            ...response,
            ...(!response.phone ? {phone: defaultPhone()} : []),
            ...(!response.address ? {address: defaultAddress()} : []),
          };
          commit('SET_USER', userModel);
        }
        return response;
      })
      .catch(() => {
        dispatch('resetToken');
      });
  },
  getBeneficiaries: ({commit}, payload) => {
    commit( 'SET_UNIFORM_LOADING', {getBeneficiaries: true});
    return UserService.getBeneficiaries(payload.token, payload.params)
      .then((data: IResponsePageableArray) => {
        commit('SET_BENEFICIARIES_PAGE', data.currentPage);
        commit('SET_BENEFICIARIES_COUNT', data.totalResult);
        commit('SET_BENEFICIARIES', data.elements);
      })
      .finally(() => commit('SET_UNIFORM_LOADING', {getBeneficiaries: false}));
  },
  checkNewPasswordToken: ({commit}, token) => {
    commit('SET_LOADING', {checkNewPasswordToken: true});
    return UserService.checkNewPasswordToken(token)
      .finally(() => commit('SET_LOADING', {checkNewPasswordToken: false}));
  },
  acceptPartner: (_, payload: IPartnerAcceptProperty) => {
    return UserService.acceptPartner(payload.token, {unitId: payload.unitId, buildingId: payload.buildingId})
      .then((response) => response);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
