
















import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({
  inheritAttrs: false
})
export default class Input extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  private validateOnBlur!: boolean;

  private get getClass() {
    return {
      'transparent': this.$attrs.transparent
    };
  }
}
