import Vue from 'vue';
import Vuex from 'vuex';
import ui, {IUiState} from '@/store/modules/ui.store';
import user, {IUserState} from '@/store/modules/user.store';
import error, {IErrorObject} from '@/store/modules/error.store';
import search, {ISearchState} from '@/store/modules/search.store';
import dictionary, {IDictionaryState} from '@/store/modules/dictionary.store';
import locale, {ILocaleStore} from '@/store/modules/locale.store';

Vue.use(Vuex);

export interface RootState {
  user: IUserState;
  ui: IUiState;
  error: IErrorObject;
  search: ISearchState;
  dictionary: IDictionaryState;
  locale: ILocaleStore;
}

export default new Vuex.Store<RootState>({
  modules: {
    user,
    ui,
    error,
    search,
    dictionary,
    locale,
  },
});
