











import {Component, Vue} from 'vue-property-decorator';
import FooterBottomNavigation from '@/components/Layout/Footer/Footer.navigation.vue';
import FooterSocials from '@/components/Layout/Footer/Footer.socials.vue';
import {EnvProvider} from '@/utilities';

@Component({
  components: {FooterBottomNavigation, FooterSocials}
})
export default class Footer extends Vue {
  private companyName: string = EnvProvider('TITLE');
  private year: number = 2020;
}
