import Cookies, { CookieSetOptions, Cookie, CookieGetOptions } from 'universal-cookie';

import { IDictionary } from '@/interfaces';

export interface ICookieService {
  all: IDictionary<any>;
  get: (name: string, options?: CookieGetOptions) => any;
  set: (name: string, value: Cookie, options?: CookieSetOptions) => void;
  remove: (name: string, options?: CookieSetOptions) => void;
}
const defaultOptions: CookieSetOptions = {
  path: '/',
  maxAge: 86400,
};
const cookies = new Cookies();
const all = (options?: CookieGetOptions) => cookies.getAll(options);
const get = (name: string, options?: CookieGetOptions) =>
  cookies.get(name, options);
const set = (
  name: string,
  value: Cookie,
  options: CookieSetOptions = defaultOptions
) => cookies.set(name, value, options);
const remove = (name: string, options?: CookieSetOptions) =>
  cookies.remove(name, options);

export const CookieService: ICookieService = { all, get, set, remove };
