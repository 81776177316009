import { Vue, Component } from 'vue-property-decorator';

import {EnvProvider} from '@/utilities/EnvProvider';

@Component
export default class UrlWithParams extends Vue {
  public mediumLink: string = EnvProvider('MEDIUM');
  public youtubeLink: string = EnvProvider('YOUTUBE');
  public instaLink: string = EnvProvider('INSTAGRAM');
  public facebookLink: string = EnvProvider('FACEBOOK');
  public linkedinLink: string = EnvProvider('LINKEDIN');

  public replaceDomain(url): string {
    return url.indexOf('.com') === -1
      ? `${url}.${this.$store.getters.country}`
      : url.replace('.com', `.${this.$store.getters.country}`);
  }

  public replace(url) {
    window.open(url, '_blank');
  }
}
