











import { Vue, Component } from 'vue-property-decorator';

@Component({
  inheritAttrs: false
})
export default class TextArea extends Vue {

  private get listeners(): object {
    return {
      ...this.$listeners,
      input: (event: Event ) => {
        return this.$emit('input', event);
      }
    };
  }
}
