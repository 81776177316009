import { ActionTree, GetterTree } from 'vuex';
import store, { RootState } from '@/store';
import DictionaryApiService, {
  DictionaryNameType,
  ICommonDictionary,
} from '@/services/api/dictionary.service.ts';
import {IDictionaryObject} from '@/interfaces';

const getDictionaryArray = (dictionaryState) => {
  return Object.keys(dictionaryState).reduce((types: IDictionaryObject[], key: string) => {
    types.push({
      name: key,
      value: dictionaryState[key][store.getters.language]
    });
    return types;
  }, []);
};

export interface IDictionaryState {
  dictionaries: ICommonDictionary[];
}

export interface ISelectItem {
  value: string|number;
  text: string|number|string[]|number[]|boolean|boolean[]|null;
}

const initialState = (): IDictionaryState => ({
  dictionaries: [],
});

const state = initialState();

const getters: GetterTree<IDictionaryState, RootState> = {
  getDictionary: (state: IDictionaryState) => {
    return (name: DictionaryNameType) => {
      const result: ICommonDictionary | undefined = state.dictionaries
        .find((dictionary: ICommonDictionary) => dictionary.name === name);
      return result ? result.values : {};
    };
  },
  getDictionaryArray: (state: IDictionaryState) => {
    return (name: DictionaryNameType) => {
      const result: ICommonDictionary | undefined =
        state.dictionaries.find((dictionary: ICommonDictionary) => dictionary.name === name);
      return result ? getDictionaryArray(result.values) : [];
    };
  },
  getSelectListFromDictionary: (state: IDictionaryState, getters) => {
    return (name: DictionaryNameType): ISelectItem[] => {
      const dictionary = Object.keys(getters.getDictionary( name )).length
        ? getters.getDictionary( name )
        : null;
      return dictionary
        ? Object.keys(dictionary).map((item: string) => ({
            value: item,
            text: dictionary[item][store.getters.language]
          }))
        : [];
    };
  },
  customSelectLabel: (state: IDictionaryState, getters) => {
    return (value: string, name: DictionaryNameType) => {
      return Object.keys(getters.getDictionary( name )).length
        ? getters.getDictionary( name )[value]
          ? getters.getDictionary( name )[value][store.getters.language]
          : getters.getDictionary( name )[value]
        : '';
    };
  },
};

const mutations = {
  ['SET_DICTIONARY'](state: IDictionaryState, payload: ICommonDictionary) {
    if (!state.dictionaries.some((item) => item.name === payload.name)) {
      state.dictionaries.push(payload);
    }
  },
};

const actions: ActionTree<IDictionaryState, RootState> = {
  getDictionary: ({commit}, dictionary: DictionaryNameType) => {
    if (!state.dictionaries.some((item) => item.name === dictionary)) {
      commit('SET_LOADING', {[dictionary]: true});
      return DictionaryApiService.getDictionary(dictionary)
        .then((data: ICommonDictionary) => commit('SET_DICTIONARY', data))
        .finally(() => commit('SET_LOADING', {[dictionary]: false}));
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
