












import UrlWithParams from '@/mixins/urlWithParams.mixin';
import {ISocialLinksList} from '@/interfaces';

export default class FooterSocials extends UrlWithParams {
  private get getSocials(): ISocialLinksList[] {
    return [
      {
        icon: '$mediumIcon',
        link: this.mediumLink,
        label: 'social link medium',
      },
      {
        icon: '$youtubeIcon',
        link: this.youtubeLink,
        label: 'social link youtube',
      },
      {
        icon: '$instagramIcon',
        link: this.instaLink,
        label: 'social link instagram',
      },
      {
        icon: '$fbIcon',
        link: this.facebookLink,
        label: 'social link facebook',
      },
      {
        icon: '$linkedinIcon',
        link: this.linkedinLink,
        label: 'social link linkedin',
      },
    ];
  }
}
