import {AxiosError} from 'axios';
import store from '@/store';
import router from '@/router';

const errorInterceptor = (error: AxiosError) => {
  store.commit('SET_ERROR_DATA', error.response?.data);

  if (error.response && (error.response.status === 409 || error.response.status === 400)) {
    return Promise.reject(error);
  } else if (error.response && error.response.status === 404) {
    router.push({ name: '404' });
    return Promise.reject(error);
  } else if (error.response && (error.response.status >= 500 || error.response.status <= 599)) {
    router.push({ name: '500' });
    return Promise.reject(error);
  } else if (error.response && error.response.status) {
    router.push({
      name: `${error.response.status}`,
      params: {
        errorCode: String(error.response.status),
        data: error.response.data
      }
    });
  }
  return Promise.reject(error);
};

export default errorInterceptor;
