import {api} from '@/services/api/api.service';
import {AxiosResponse} from 'axios';
import {InsuranceLocaleType, LeasingToolType} from '@/interfaces';

export type DictionaryNameType
  = 'landlord-leasing-tools';

export interface ICommonDictionary {
  name: string;
  values: ICommonDictionaryValues<LeasingToolType, InsuranceLocaleType>;
}

export interface ICommonDictionaryValues<Type, Key> {
  [Type: string]: {
    [Key: string]: string;
  };
}

export interface IDictionaryApiService {
  getDictionary: (dictionary: DictionaryNameType) => Promise<ICommonDictionary>;
}

const getDictionary = (dictionary: DictionaryNameType): Promise<ICommonDictionary> => api
  .get(`/dictionary/${dictionary}`)
  .then((response: AxiosResponse<ICommonDictionary>) => response.data)
  .catch((error) => Promise.reject(error));

const DictionaryApiService: IDictionaryApiService = {
  getDictionary,
};

export default DictionaryApiService;
