import {api} from '@/services/api/api.service';
import {AxiosPromise, AxiosResponse} from 'axios';
import {
  LastCompletedPageType,
  LandlordType,
  FurnishedType,
  LeasingToolType,
  PortfolioSizeType,
  PropertiesType,
  Language,
  ISuggestionsPlaces,
  ISuggestionAddress,
  IDocument,
  ISuggestionCheck,
  ISuggestionAddressChecked, landlordTypeEnum,
} from '@/interfaces';
import {ISearchRequest} from '@/store/modules/search.store';

export interface IUser {
  lastCompletedPage: LastCompletedPageType | null;
  token: string;
  type: LandlordType | null;
  firstName: string;
  lastName: string;
  email: string;
  phone: IPhone | null;
  companyName: string;
  address: IAddress;
  furnished: FurnishedType | null;
  leasingTool: LeasingToolType | null;
  manualLeasingTool: string;
  portfolioSize: PortfolioSizeType | null;
  propertiesType: PropertiesType | null;
  offerAlternativeSecurityDeposit: boolean | null;
  newsAgreement: boolean | null;
}

export interface IPhone {
  dialCode: string | null;
  number: string | null;
}

export interface IAddressManual {
  street: string | null;
  streetNumber: string | null;
  zip: string | null;
  city: string | null;
  country?: string | null;
}

export interface IPropertyAddressManual {
  street: string | null;
  streetNumber: string | null;
  zip: string | null;
  city: string | null;
}

export interface IAddress {
  address: IAddressManual;
  fullAddress: string | null;
  suggestionAddress: ISuggestionAddress | null;
}

export interface IPropertyAddress {
  address: IPropertyAddressManual;
  fullAddress: string | null;
  suggestionAddress: ISuggestionAddress | null;
}

export interface IAddressRequest {
  address: IAddressManual | null;
  suggestSession: string | null;
  suggestionAddress: ISuggestionAddress;
}

export interface IPasswordChangeInfo {
  password: string;
  reEnteredPassword: string;
  token: string;
}

export interface IConfirmLandlordDetailsInfo {
  token: string;
  isManualAddress: boolean;
}

export interface IPolicyAccess {
  address: ISuggestionAddress;
  landlordExisting: boolean;
  propertyKnown: boolean;
  type: landlordTypeEnum;
  message: string;
  certificateSampleDocument: IDocument;
  tenantName: string;
  tenantEmail: string;
  insuranceStartDate: string;
  insuranceEndDate: string;
  deposit: 0;
  currency: string;
  tenantAddress: string;
  landlordType: string;
  landlordName: string;
  companyName: string;
  landlordEmail: string | null;
  phoneNumber: IPhone | null;
  landlordAddress: IAddressRequest;
  rentContractId: string | null;
}

export interface IPolicyLandlordDetails {
  type: string;
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  phone: IPhone | null;
  address: IAddress;
  propertyType: string;
  unitName: string | null;
  beneficiary: IBeneficiary;
  isLandlordBeneficiary: boolean | null;
}

export interface IBeneficiary {
  id: string | null;
  name: string;
  tag?: string | null;
  address: IAddress;
}

export interface INewBeneficiaryRequest {
  id: number | null;
  name: string;
  tag?: string | null;
  address: IAddressRequest | null;
}

export interface IResponsePageableArray {
  currentPage: number;
  totalResult: number;
  pageSize: number;
  sortField: string;
  sortDirection: string;
  elements: [];
}

export interface IAddressRequestV2 {
  addressId: string | null;
  suggestSession: string | null;
  manualAddress: IAddressManual | null;
}

export interface IPolicyExistingLandlordRequest {
  propertyType: string;
  unitName: string | null;
  beneficiary: INewBeneficiaryRequest;
}

export interface IRateInfo {
  relationship: number;
  checkout: number;
  recommend: string;
  additionalMessage: string;
  id: string;
}

export interface IUserRequestParams {
  token: string;
}

export interface IUserRequestType {
  token?: string;
  type: string;
}

export interface IUserRequestDetails extends IUserRequestParams {
  firstName: string;
  lastName: string;
  email: string;
  phone: IPhone;
  companyName: string;
  address: IAddressRequest;
}

export interface IUserRequestPropertyType extends IUserRequestParams {
  propertiesType: PropertiesType;
}

export interface IUserRequestPortfolioSizeType extends IUserRequestParams {
  portfolioSize: PortfolioSizeType;
}

export interface IUserRequestLeasingTools extends IUserRequestParams {
  leasingTool: LeasingToolType | null;
  manualLeasingTool: string;
}

export interface IUserRequestSecurityDeposit extends IUserRequestParams {
  offerAlternativeSecurityDeposit: boolean;
}

export interface IUserRequestFurnished extends IUserRequestParams {
  furnished: FurnishedType;
}

export interface IUserRequestAgreement extends IUserRequestParams {
  newsAgreement: boolean;
  token: string;
  language: Language;
}

export interface IPartnerPropertyRequest {
  query: string;
}

export interface IPolicyAcceptanceRequest {
  buildingId: string;
  unitId: string;
}

export interface IPartnerAcceptProperty {
  token: string;
  unitId: string;
  buildingId: string;
}

export interface IUserService {
  saveUserType: (data: IUserRequestType) => Promise<IUser>;
  saveUserDetails: (data: IUserRequestDetails) => Promise<IUser>;
  saveUserPropertyType: (data: IUserRequestPropertyType) => Promise<IUser>;
  saveUserPortfolio: (data: IUserRequestPortfolioSizeType) => Promise<IUser>;
  saveUserLeasingTools: (data: IUserRequestLeasingTools) => Promise<IUser>;
  saveUserSecurityDeposit: (data: IUserRequestSecurityDeposit) => Promise<IUser>;
  saveUserFurnished: (data: IUserRequestFurnished) => Promise<IUser>;
  saveUserAgreement: (data: IUserRequestAgreement) => Promise<IUser>;
  getAcceptPolicy: (token: string) => Promise<IPolicyAccess>;
  confirmLandlordAcceptance: (token: string, data: IPolicyExistingLandlordRequest) => Promise<IUser>;
  confirmLandlordDetails: (token: string, data: IPolicyLandlordDetails) => Promise<IUser>;
  confirmAccount: (data: IPasswordChangeInfo) => Promise<IUser>;
  setRate: (data: IRateInfo) => Promise<object>;
  rateCheck: (id: string) => Promise<boolean>;
  getUserByToken: (token: string) => Promise<IUser>;
  getPropertiesAddresses: (request: ISearchRequest) => Promise<ISuggestionsPlaces>;
  getBeneficiaries: (token: string, page: number) => Promise<IResponsePageableArray>;
  checkNewPasswordToken: (token: string) => Promise<AxiosPromise>;
  suggestionCheck: (data: ISuggestionCheck) => Promise<ISuggestionAddressChecked>;
  partnerUnits: ( token: string, buildingId: string, data: IPartnerPropertyRequest) => Promise<IAddress>;
  partnerBuildings: (token: string, data: IPartnerPropertyRequest) => Promise<IAddress>;
  acceptPartner: (token: string, data: IPolicyAcceptanceRequest) => Promise<AxiosPromise>;
}

const saveUserType = (data: IUserRequestType): Promise<IUser> => api
  .post('/onboarding/landlord/type', data)
  .then((response: AxiosResponse<IUser>) => response.data)
  .catch((error) => Promise.reject(error));

const saveUserDetails = (data: IUserRequestDetails): Promise<IUser> => api
  .post('/onboarding/landlord/details', data)
  .then((response: AxiosResponse<IUser>) => response.data)
  .catch((error) => Promise.reject(error));

const saveUserPropertyType = (data: IUserRequestPropertyType): Promise<IUser> => api
  .post('/onboarding/landlord/properties-type', data)
  .then((response: AxiosResponse<IUser>) => response.data)
  .catch((error) => Promise.reject(error));

const saveUserPortfolio = (data: IUserRequestPortfolioSizeType): Promise<IUser> => api
  .post('/onboarding/landlord/portfolio', data)
  .then((response: AxiosResponse<IUser>) => response.data)
  .catch((error) => Promise.reject(error));

const saveUserLeasingTools = (data: IUserRequestLeasingTools): Promise<IUser> => api
  .post('/onboarding/landlord/leasing-tools', data)
  .then((response: AxiosResponse<IUser>) => response.data)
  .catch((error) => Promise.reject(error));

const saveUserSecurityDeposit = (data: IUserRequestSecurityDeposit): Promise<IUser> => api
  .post('/onboarding/landlord/security-deposit', data)
  .then((response: AxiosResponse<IUser>) => response.data)
  .catch((error) => Promise.reject(error));

const saveUserFurnished = (data: IUserRequestFurnished): Promise<IUser> => api
  .post('/onboarding/landlord/furnished', data)
  .then((response: AxiosResponse<IUser>) => response.data)
  .catch((error) => Promise.reject(error));

const saveUserAgreement = (data: IUserRequestAgreement): Promise<IUser> => api
  .post('/onboarding/landlord/submit', data)
  .then((response: AxiosResponse<IUser>) => response.data)
  .catch((error) => Promise.reject(error));

const getUserByToken = (token: string): Promise<IUser> => api
  .get(`/onboarding/landlord/${token}`)
  .then((response: AxiosResponse<IUser>) => response.data)
  .catch((error) => Promise.reject(error));

const getAcceptPolicy = (token: string): Promise<IPolicyAccess> => api
  .get(`/onboarding/tenant/policy-acceptance/${token}`)
  .then((response: AxiosResponse<IPolicyAccess>) => response.data)
  .catch((error) => Promise.reject(error));

const confirmLandlordAcceptance = (token: string, data: IPolicyExistingLandlordRequest): Promise<IUser> => api
  .post(`/onboarding/tenant/policy-acceptance/${token}/landlord-exists`, data)
  .then((response: AxiosResponse<IUser>) => response.data)
  .catch((error) => Promise.reject(error));

const confirmLandlordDetails = (token: string, data: IPolicyLandlordDetails): Promise<IUser> => api
  .post(`/onboarding/tenant/policy-acceptance/${token}`, data)
  .then((response: AxiosResponse<IUser>) => response.data)
  .catch((error) => Promise.reject(error));

const confirmAccount = (data: IPasswordChangeInfo): Promise<IUser> => api
  .post('/user/confirm-account', data)
  .then((response: AxiosResponse<IUser>) => response.data)
  .catch((error) => Promise.reject(error));

const setRate = (data: IRateInfo): Promise<object> => api
  .post('/rating/landlord', data)
  .then((response: AxiosResponse<object>) => response.data)
  .catch((error) => Promise.reject(error));

const rateCheck = (id: string): Promise<boolean> => api
  .get(`/rating/${id}`)
  .then((response: AxiosResponse<boolean>) => response.data)
  .catch((error) => Promise.reject(error));

const getPropertiesAddresses = (params: ISearchRequest): Promise<ISuggestionsPlaces> => api
  // @ts-ignore
  .get('/suggestion', {params, allowDuplication: true})
  .then((response: AxiosResponse<ISuggestionsPlaces>) => response.data)
  .catch((error) => Promise.reject(error));

const getBeneficiaries = (token: string, params): Promise<IResponsePageableArray> => api
  .get(`/onboarding/tenant/policy-acceptance/${token}/landlord-exists/beneficiary`, {params})
  .then((response: AxiosResponse<IResponsePageableArray>) => response.data)
  .catch((error) => Promise.reject(error));

const checkNewPasswordToken = (token: string): Promise<AxiosPromise> => api
  .get(`/user/confirm-account/${token}`)
  .then((response: AxiosResponse) => response.data)
  .catch((error) => Promise.reject(error));

const suggestionCheck = (data: ISuggestionCheck) => api
  // @ts-ignore
  .post('/suggestion/check', data, {allowDuplication: true})
  .then((response: AxiosResponse<ISuggestionAddressChecked>) => response.data)
  .catch((error) => Promise.reject(error));

const partnerUnits = (token, buildingId, data: IPartnerPropertyRequest) => api
  .post(`/search/unit/accept/${token}/${buildingId}`, data)
  .then((response: AxiosResponse<IAddress>) => response.data)
  .catch((error) => Promise.reject(error));

const partnerBuildings = (token, data: IPartnerPropertyRequest): Promise<IAddress> => api
  .post(`/search/building/accept/${token}`, data)
  .then((response: AxiosResponse<IAddress>) => response.data)
  .catch((error) => Promise.reject(error));

const acceptPartner = (token, data: IPolicyAcceptanceRequest) => api
  .post(`/onboarding/tenant/policy-acceptance/${token}/partner`, data)
  .then((response: AxiosResponse) => response.data)
  .catch((error) => Promise.reject(error));

const UserService: IUserService = {
  saveUserType,
  saveUserDetails,
  saveUserPropertyType,
  saveUserPortfolio,
  saveUserLeasingTools,
  saveUserSecurityDeposit,
  saveUserFurnished,
  saveUserAgreement,
  getUserByToken,
  getAcceptPolicy,
  confirmLandlordAcceptance,
  confirmLandlordDetails,
  confirmAccount,
  setRate,
  rateCheck,
  getPropertiesAddresses,
  getBeneficiaries,
  checkNewPasswordToken,
  suggestionCheck,
  partnerUnits,
  partnerBuildings,
  acceptPartner,
};

export default UserService;
