
























import { Component, Vue, Prop } from 'vue-property-decorator';
import SelectIcon from '@/assets/icons/arrow-down.svg';

@Component({
  inheritAttrs: false,
  components: {
    SelectIcon
  }
})
export default class Select extends Vue {
  @Prop({
    type: Array,
    required: false,
    default: []
  })
  public items!: [];
}
